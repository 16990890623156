import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import ImageGallery from "../components/ImageGallery"

const PhotoGallery = ( {data} ) => {
  
  return ( 
    <Layout formTitle="Let us know how we can help create your dream log home.">
      <Seo 
        title="Custom Designed Log Homes"
        description="An Authentic Log Home is the perfect choice if you value quality, affordability, and truly personal custom design." 
      />
      <div className="container mx-auto relative pt-32 md:pt-48">
        <h1 className="md:text-center">Log Home Gallery</h1>
        <p className="md:text-center mb-16 md:mb-24">Take a look through our past log home builds from over the decades!</p>
        <ImageGallery data={data} />
      </div>
    </Layout>
   );
}
 
export default PhotoGallery;

export const pageQuery = graphql`
query GalleryPhotos {
  allFile(
    filter: {relativeDirectory: {eq: "gallery"}}
    sort: {fields: modifiedTime, order: DESC}
  ) {
    edges {
      node {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100, placeholder: BLURRED)
        }
      }
    }
  }
}
`