import React, { useState } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { MdClose } from 'react-icons/md';
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper";

const ImageGallery = ( {data} ) => {
  const [openHighlight, setOpenHighlight] = useState(false)
  const [slideIndex, setSlideIndex] = useState(null);
  const images = data.allFile.edges.sort()

  console.log(images)

  const handleKeyDown = (e, index) => {
    if (e.key === 'Enter') {
      setOpenHighlight(true);
      setSlideIndex(index);
      window.scrollTo(0, 250);
    }
  }

  return (
    <>
      {openHighlight && 
        <div className="galleryHighlight">
          <MdClose className="gallery-close-btn" onClick={() => setOpenHighlight(false)}  />
          <Swiper navigation={true} modules={[Navigation]} initialSlide={slideIndex}>
            {images.map((image) => {
              return(
                <SwiperSlide key={image.node.id} className="self-center">
                    <GatsbyImage 
                      image={image.node.childImageSharp.gatsbyImageData}
                      alt="A custom log home in Vermont"
                    />
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
      }

      {!openHighlight &&   
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {images.map((image, index) => {
            return(
              <div
              className="singleImage"
              key={image.node.id}
              onClick={() => {setOpenHighlight(true); setSlideIndex(index); window.scrollTo(0, 250);}}
              onKeyDown={(e) =>  handleKeyDown(e, index)}
              role="button"
              tabIndex={0}
              >
                <GatsbyImage 
                  image={image.node.childImageSharp.gatsbyImageData}
                  alt="A custom log home in Vermont"
                  className="object-cover"
                />
              </div>
            )
          })}
        </div>
      }
    </>
  )
}
 
export default ImageGallery;